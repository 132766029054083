import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { attachImageEdgesRecursive } from "../utils/functions"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/sections/hero"

import TimeLine from "../components/sections/timeline"
import { StaticImage } from "gatsby-plugin-image"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Fade } from "react-reveal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faAngleDoubleDown,
	faChartLine,
	faPeopleArrows,
	faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

const wir = {
	seo: {
		title: "Erfolgreiches Recruiting ermöglichen ist unser Job",
		description:
			"Erfolgreiches Internes Recruiting – lernen Sie uns kennen: ✔️ Unsere Geschichte ✔️ Ihre Vorteile ✔️ Sinn & Zweck ✔️ Prinzipien",
	},
	hero: {
		h1: "Erfolgreiches Recruiting möglich machen ist unser Job! Hier zeigen wir Ihnen …",
		checklist: [
			"… was der Wanderfalke auf dem Bild zu suchen hat",
			"… wer wir sind & wie wir ticken",
			"… unsere Geschichte auf",
		],
		btnText: "Mehr erfahren",
		btnLink: "#wir",
		image: "wir_hero.jpg",
	},
	timeline: {
		timeline: [
			{
				title: "Einstieg ins Recruiting",
				description:
					"Unser Geschäftsführer startet seine berufliche Laufbahn in der Personaldienstleistung intern als Personaldisponent. Er beendete seine Laufbahn als Angestellter mit überregionaler Verantwortung in den Bereichen Recruiting & Vertrieb",
				date: "1999",
				icon: "Haus",
				highlight: true,
			},
			{
				title: "Schritt in die Selbstständigkeit",
				description:
					"Mit einer auf die Personaldienstleistungsbranche spezialisierten Personalberatungsagentur. Zum Portfolio gehören neben der Fach- und Führungskräftevermittlung auch Schulungen und Workshops im Bereich Vertrieb und Mitarbeitergewinnung",
				date: "2002",
				icon: "Lampe",
				highlight: false,
			},
			{
				title: "Gründung D.I.E. STRATEGIE GmbH",
				description:
					"Schwerpunkt ist die Recruitingoptimierung von kleinen und mittelständischen Unternehmen in der freien Wirtschaft. Des Weiteren werden Recruiter in Festanstellung vermittelt und RPO-Dienstleistungen angeboten",
				date: "2017",
				icon: "Stern",
				highlight: false,
			},
			{
				title: "Gründung der VIPCRUITER GmbH",
				description:
					"Die Vermittlung von Recruitern in Festanstellung und RPO-Dienstleistungen werden in der neuen Gesellschaft gebündelt und fokussiert",
				date: "2020",
				icon: "Stern",
				highlight: false,
			},
		],
	},
}

const punkte = [
	{
		question: "Schnelligkeit",
		answer:
			"Einer der wichtigsten Erfolgsfaktoren für wirksames Finden & Einstellen von Mitarbeitern ist die Geschwindigkeit – wir „liefern“ alle Recruitinglösungen innerhalb von 7 Werktagen.",
	},
	{
		question: "Erfahrung",
		answer:
			"Unser Team hat 46 Jahre Erfahrung im operativen Recruiting & über 20 Jahre Erfahrung als Recruitingoptimierer von Unternehmen aus der freien Wirtschaft.",
	},
	{
		question: "Fokussierung",
		answer:
			"Wir konzentrieren uns nur auf das, was wir wirklich können: pragmatische Recruitingoptimierung von A bis Z.",
	},
	{
		question: "Individualität",
		answer:
			"Jede Branche und jedes Unternehmen muss sich eigenen Herausforderungen bei der Mitarbeitersuche stellen – wir passen uns Ihren Gegebenheiten & Bedürfnissen an.",
	},
	{
		question: "Differenzierung",
		answer:
			"Im Vergleich zu vielen Agenturen haben wir kein Interesse, Stellenpakete, Recruitingtrends oder sonstige nicht nutzbringende „Tools“ zu verkaufen – für uns ist entscheidend: Ihre konkreten Recruitingprobleme zu lösen.",
	},
	{
		question: "Branchen-Know-How",
		answer:
			"Ob Handel, Dienstleistung, Industrie oder reine Onlineunternehmen – wir liefern Ihnen Recruitinglösungen, die Sie messbar nach vorne bringen.",
	},
	{
		question: "Geradlinigkeit",
		answer:
			"Wir zeigen Ihnen sachlich und ohne Umschweife Ihre Schwachstellen im Recruitment auf und führen Sie motivierend durch den „Recruitingdschungel“ – beginnend bei unserem ersten Telefonat bis zum Projektende.",
	},
	{
		question: "Freude",
		answer:
			"Klingt banal und doch ist es so wichtig – uns bereitet es Freude und es ist Ansporn für uns, Ihr Unternehmen in das nächste Recruitinglevel zu begleiten.",
	},
]

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const Wir = () => (
	<StaticQuery
		query={graphql`
			query {
				images: allFile {
					edges {
						node {
							relativePath
							childImageSharp {
								gatsbyImageData(placeholder: TRACED_SVG)
							}
						}
					}
				}
			}
		`}
		render={(data) => {
			attachImageEdgesRecursive(data.images.edges, wir)

			return (
				<Layout>
					<Seo title={wir.seo?.title} description={wir.seo?.description} />
					<Hero data={wir.hero} />

					<DividerTopWhite />

					<div className="w-full bg-white" id="wir">
						<div className="sectionwidth sectionpadding">
							<h2 className="h1_5 text-primary font-bold text-center mb-8">
								Das zeichnet uns aus
							</h2>
							<div className="flex flex-col md:flex-row gap-8 justify-center">
								<div className="flex items-center justify-center">
									<StaticImage
										className="border-2 border-secondary rounded-lg shadow-lg"
										src="../images/das-zeichnet-uns-aus.png"
										alt="Karriere Website"
										placeholder="tracedSVG"
										width={258}
									/>
								</div>

								<dl className="mt-6 space-y-2 divide-y w-full lg:w-80 divide-gray-200">
									{punkte.map((faq) => (
										<Disclosure as="div" key={faq.question} className="pt-2">
											{({ open }) => (
												<>
													<dt className="text-lg">
														<Disclosure.Button
															className={
																"text-left w-full flex justify-between items-start text-gray-400 " +
																(!open ? "-mb-2" : "")
															}
														>
															<span className="font-medium text-gray-900">
																{faq.question}
															</span>
															<span className="ml-6 h-7 flex items-center">
																<ChevronDownIcon
																	className={classNames(
																		open ? "-rotate-180" : "rotate-0",
																		"h-6 w-6 transform"
																	)}
																	aria-hidden="true"
																/>
															</span>
														</Disclosure.Button>
													</dt>
													<Fade when={open} collapse>
														<Disclosure.Panel
															static
															as="dd"
															className="mt-2 pr-12"
														>
															<p className="text-base text-gray-500">
																{faq.answer}
															</p>
														</Disclosure.Panel>
													</Fade>
												</>
											)}
										</Disclosure>
									))}
								</dl>
							</div>
						</div>
					</div>

					<DividerTop bg="bg-white" />
					<DividerBotBlue />

					<TimeLine data={wir.timeline} />

					<DividerTopBlue />

					<div className="w-full bg-white">
						<div className="sectionwidth sectionpadding">
							<h2 className="h1_5 text-primary font-bold text-center mb-4">
								Sinn und Zweck
							</h2>
							<p className="text-center mb-8">
								Sinn und Zweck unseres Unternehmens ist es, unsere Kunden im
								Recruiting erfolgreicher zu machen! Indem wir ihnen helfen …
							</p>
							<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
								<Fade left delay={100}>
									<div className="flex flex-col items-center gap-4">
										<FontAwesomeIcon
											icon={faAngleDoubleDown}
											size="4x"
											className="text-secondary"
										/>
										<p className="text-primary font-semibold text-center">
											… Recruitingkosten zu senken
										</p>
									</div>
								</Fade>
								<Fade left delay={300}>
									<div className="flex flex-col items-center gap-4">
										<FontAwesomeIcon
											icon={faChartLine}
											size="4x"
											className="text-secondary"
										/>
										<p className="text-primary font-semibold text-center">
											… schneller & erfolgreicher zu recruiten
										</p>
									</div>
								</Fade>
								<Fade left delay={500}>
									<div className="flex flex-col items-center gap-4">
										<FontAwesomeIcon
											icon={faPeopleArrows}
											size="4x"
											className="text-secondary"
										/>
										<p className="text-primary font-semibold text-center">
											… die Mitarbeiterfluktuation zu minimieren
										</p>
									</div>
								</Fade>
								<Fade left delay={700}>
									<div className="flex flex-col items-center gap-4">
										<FontAwesomeIcon
											icon={faThumbsUp}
											size="4x"
											className="text-secondary"
										/>
										<p className="text-primary font-semibold text-center">
											… mehr Spaß und Freude am Recruiting zu haben
										</p>
									</div>
								</Fade>
							</div>
						</div>
					</div>

					<DividerTop bg="bg-white" />
					<DividerBot bg="bg-white" />

					<div className="w-full bg-white">
						<div className="sectionwidth sectionpadding">
							<h2 className="h1_5 text-primary font-bold text-center mb-8">
								Prinzipien
							</h2>

							<div className="grid grid-cols-1 lg:grid-cols-2">
								<div className="flex items-center justify-center">
									<Fade left>
										<StaticImage
											src="../images/falke.png"
											alt="Karriere Website"
											placeholder="tracedSVG"
											width={300}
										/>
									</Fade>
								</div>
								<div>
									<h3 className="h3 text-primary font-bold mb-4">
										Schnelligkeit
									</h3>
									<p className="mb-8">
										Der Wanderfalke gilt als schnellstes Lebewesen auf unserem
										Planeten – wir haben den Anspruch, die Wanderfalken in der
										Recruitingoptimierung zu sein und Ihre Recruitingprobleme
										innerhalb von 7 Werktagen zu lösen.
									</p>
									<h3 className="h3 text-primary font-bold mb-4">Sicherheit</h3>
									<p className="mb-8">
										Wenn Sie uns mit einem Auftrag Ihr Vertrauen schenken,
										genießen Sie die Sicherheit, dass das Projekt erfolgreich
										innerhalb des vereinbarten Leistungszeitraumes abgeschlossen
										wird – Punkt – ohne Wenn und Aber!
									</p>
								</div>
							</div>
						</div>
					</div>
				</Layout>
			)
		}}
	/>
)

export default Wir

const DividerTop = ({ bg }) => {
	if (bg)
		return (
			<div className="w-full">
				<div className={"h-[100px] " + bg}></div>
				<div
					style={{
						"background-image":
							"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNlZWUiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
						"background-size": "100% 100px",
						bottom: "0",
						height: "100px",
						"z-index": "10",
						width: "100%",
						marginTop: "-100px",
					}}
				></div>
			</div>
		)
	return (
		<div
			style={{
				"background-image":
					"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNlZWUiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
				"background-size": "100% 100px",
				bottom: "0",
				height: "100px",
				"z-index": "10",
				width: "100%",
				marginTop: "-100px",
			}}
		></div>
	)
}

const DividerBot = ({ bg }) => {
	if (bg)
		return (
			<div className="w-full">
				<div className={"h-[100px] " + bg}></div>
				<div
					style={{
						"background-image":
							"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNlZWVlZWUiPjxwYXRoIGQ9Ik0xMjgwIDBMNjQwIDcwIDAgMHYxNDBsNjQwLTcwIDY0MCA3MFYweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDBIMGw2NDAgNzAgNjQwLTcweiIvPjwvZz48L3N2Zz4=)",
						"background-size": "100% 100px",
						bottom: "0",
						height: "100px",
						"z-index": "10",
						width: "100%",
						marginTop: "-100px",
					}}
				></div>
			</div>
		)

	return (
		<div
			style={{
				"background-image":
					"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNlZWVlZWUiPjxwYXRoIGQ9Ik0xMjgwIDBMNjQwIDcwIDAgMHYxNDBsNjQwLTcwIDY0MCA3MFYweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDBIMGw2NDAgNzAgNjQwLTcweiIvPjwvZz48L3N2Zz4=)",
				"background-size": "100% 100px",
				bottom: "0",
				height: "100px",
				"z-index": "10",
				width: "100%",
				marginTop: "-100px",
			}}
		></div>
	)
}

const DividerTopBlue = ({ bg }) => {
	if (bg)
		return (
			<div className="w-full">
				<div className={"h-[100px] " + bg}></div>
				<div
					style={{
						"background-image":
							"url( data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNFRkY2RkYiPjxwYXRoIGQ9Ik0xMjgwIDBMNjQwIDcwIDAgMHYxNDBsNjQwLTcwIDY0MCA3MFYweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDBIMGw2NDAgNzAgNjQwLTcweiIvPjwvZz48L3N2Zz4=)",
						"background-size": "100% 100px",
						bottom: "0",
						height: "100px",
						"z-index": "10",
						width: "100%",
						marginTop: "-100px",
					}}
				></div>
			</div>
		)
	return (
		<div
			style={{
				"background-image":
					"url( data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNFRkY2RkYiPjxwYXRoIGQ9Ik0xMjgwIDBMNjQwIDcwIDAgMHYxNDBsNjQwLTcwIDY0MCA3MFYweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0xMjgwIDBIMGw2NDAgNzAgNjQwLTcweiIvPjwvZz48L3N2Zz4=)",
				"background-size": "100% 100px",
				bottom: "0",
				height: "100px",
				"z-index": "10",
				width: "100%",
			}}
		></div>
	)
}

const DividerBotBlue = ({ bg }) => {
	if (bg)
		return (
			<div className="w-full">
				<div className={"h-[100px] " + bg}></div>
				<div
					style={{
						"background-image":
							"url( data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNFRkY2RkYiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
						"background-size": "100% 100px",
						bottom: "0",
						height: "100px",
						"z-index": "10",
						width: "100%",
						marginTop: "-100px",
					}}
				></div>
			</div>
		)

	return (
		<div
			style={{
				"background-image":
					"url( data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNFRkY2RkYiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
				"background-size": "100% 100px",
				bottom: "0",
				height: "100px",
				"z-index": "10",
				width: "100%",
				marginTop: "-100px",
			}}
		></div>
	)
}

const DividerTopWhite = ({ bg }) => {
	if (bg)
		return (
			<div className="w-full">
				<div className={"h-[100px] " + bg}></div>
				<div
					style={{
						"background-image":
							"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
						"background-size": "100% 100px",
						bottom: "0",
						height: "100px",
						"z-index": "10",
						width: "100%",
						marginTop: "-100px",
					}}
				></div>
			</div>
		)
	return (
		<div
			style={{
				"background-image":
					"url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0wIDE0MGw2NDAtNzAgNjQwIDcwVjBMNjQwIDcwIDAgMHYxNDB6IiBmaWxsLW9wYWNpdHk9Ii41Ii8+PHBhdGggZD0iTTAgMTQwaDEyODBMNjQwIDcwIDAgMTQweiIvPjwvZz48L3N2Zz4=)",
				"background-size": "100% 100px",
				bottom: "0",
				height: "100px",
				"z-index": "10",
				width: "100%",
				marginTop: "-100px",
			}}
		></div>
	)
}
