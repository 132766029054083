import React from "react"

import {
	LightBulbIcon,
	UsersIcon,
	StarIcon,
	TrendingUpIcon,
	HomeIcon,
	CakeIcon,
	KeyIcon,
} from "@heroicons/react/outline"

import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"

// NOTE when exposed to API Icons need to be set

const TimeLine = function ({ data }) {
	return (
		<div className="bg-blue-50 w-full relative">
			<div id="zeitreise" className="overflow-x-hidden">
				<VerticalTimeline>
					{data?.timeline?.map((item, index) => {
						let icon
						switch (item.icon) {
							case "Lampe":
								icon = <LightBulbIcon />
								break
							case "Personen":
								icon = <UsersIcon />
								break
							case "Stern":
								icon = <StarIcon />
								break
							case "Aufwärts":
								icon = <TrendingUpIcon />
								break
							case "Haus":
								icon = <HomeIcon />
								break
							case "Kuchen":
								icon = <CakeIcon />
								break
							case "Schlüssel":
								icon = <KeyIcon />
								break
							default:
								icon = <StarIcon />
								break
						}
						return (
							<VerticalTimelineElement
								className="vertical-timeline-element--work"
								date={item.date}
								textClassName="border-t-4 border-primary"
								iconClassName="bg-secondary text-white"
								icon={icon}
							>
								<h3 className="font-bold">{item.title}</h3>
								<p>{item.description}</p>
							</VerticalTimelineElement>
						)
					})}
				</VerticalTimeline>
			</div>
		</div>
	)
}

export default TimeLine
